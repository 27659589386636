import { Header } from "../src/components/header/header";
import { Container } from "../src/components/container/container";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import classes from "./index.module.scss";
import { SplitSection } from "../src/components/split-section/split-section";
import Image from "next/image";
import {
  VisualContentEntry,
  VisualContentSection,
} from "../src/components/visual-content-section/visual-content-section";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Login from "@mui/icons-material/Login";
import clsx from "clsx";
import Link from "next/link";
import { PAGE_OVERVIEW } from "../src/routes";
import { Team } from "../src/components/team/team";
import Head from "next/head";
import { Sponsors } from "../src/components/sponsors/sponsors";
import { Footer } from "../src/components/footer/footer";
import { IntroTeaser } from "../src/components/intro-teaser/intro-teaser";
import { useSession } from "next-auth/react";

export default function Home() {
  const [t] = useTranslation();
  const session = useSession();

  return (
    <>
      <Head>
        <title>{t("general.title")}</title>
        <meta name="description" content={t("pages.main.entrySection.text1")} />
      </Head>
      <Container padded>
        <Header showUserMenu={false}>
          {session.status === "authenticated" && (
            <>
              <div className={classes.headerDashboardCTA}>
                <Link href={PAGE_OVERVIEW} locale={"de"}>
                  <Button>{t("general.open_dashboard")}</Button>
                </Link>
              </div>
              <div className={classes.headerDashboardCTAMobile}>
                <Link href={PAGE_OVERVIEW}>
                  <IconButton color={"primary"}>
                    <Login />
                  </IconButton>
                </Link>
              </div>
            </>
          )}
        </Header>
        <IntroTeaser />

        <VisualContentSection className={classes.section}>
          <VisualContentEntry title={t("pages.main.visualSection.title1")}>
            <Typography
              variant={"body1"}
              fontWeight={"lighter"}
              dangerouslySetInnerHTML={{
                __html: t("pages.main.visualSection.text1"),
              }}
            ></Typography>
          </VisualContentEntry>

          <VisualContentEntry title={t("pages.main.visualSection.title2")}>
            <Typography
              variant={"body1"}
              fontWeight={"lighter"}
              dangerouslySetInnerHTML={{
                __html: t("pages.main.visualSection.text2"),
              }}
            ></Typography>
          </VisualContentEntry>
          <VisualContentEntry title={t("pages.main.visualSection.title3")}>
            <Typography
              variant={"body1"}
              fontWeight={"lighter"}
              dangerouslySetInnerHTML={{
                __html: t("pages.main.visualSection.text3"),
              }}
            ></Typography>
          </VisualContentEntry>
        </VisualContentSection>

        <SplitSection
          className={clsx(classes.section, classes.contactCTASection)}
        >
          <div className={classes.centeredColumn}>
            <Image
              src={"/edumap_visual.png"}
              alt={"Beispiel Screenshot der Platform"}
              width={1471}
              height={1119}
            />
          </div>
          <div className={classes.centeredColumn}>
            <div className={classes.mapSectionContent}>
              <Typography
                className={classes.contactCTATitle}
                variant={"h4"}
                color={"primary"}
              >
                {t("pages.main.cta.title")}
              </Typography>
              <Typography
                className={classes.mapSectionDescription}
                dangerouslySetInnerHTML={{
                  __html: t("pages.main.cta.content"),
                }}
              />
              <Link href={PAGE_OVERVIEW}>
                <Button
                  className={classes.mapSectionCTA}
                  variant={"contained"}
                  color={"primary"}
                  endIcon={<ArrowForward />}
                  size={"large"}
                >
                  {session.status === "authenticated"
                    ? t("general.open_dashboard")
                    : t("overview.login_cta")}
                </Button>
              </Link>
            </div>
          </div>
        </SplitSection>
      </Container>
      <Team />
      <Container padded>
        <Sponsors />
      </Container>
      <Footer hideLinks />
    </>
  );
}
